import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import Layout from '../components/layout';
import Row from '../components/Row';
import InputComponent from '../components/utils/InputComponent';
import Footer from '../components/footer';
import CustomLink from '../components/utils/CustomLink';
import Nav from '../components/Nav';
import { navigateTo } from "gatsby-link";
import axios from 'axios';
import LongArrow from '../components/svg/LongArrow';
import { useTransition } from 'react-spring';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const SectionOne = styled.section`
    ${tw`relative z-20 py-24`};
`

const FormWrapper = styled.div`
    ${tw`relative z-20 w-full flex flex-wrap py-8`};
    border-radius: 1.618rem;
`

const SubmitButton = styled.button`
    
    ${tw`flex 
        items-center 
        appearance-none 
        outline-none 
        shadow-none 
        border-none 
        font-black 
        text-150gr 
        font-primary 
        tracking-tight 
        pl-4 
        pr-8 
        bg-transparent`};
        color: ${p => p.disabled ? 'hsla(234, 5%, 60%, 0.3)' : 'hsla(234, 52%, 60%,1)'}; 
        cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
`

const HeroTitle = styled.h1`
    ${tw`font-sans font-black tracking-tight text-grey-dark w-full md:w-auto text-2gr md:text-3gr lg:text-4gr`};
`

const TitleDialogue = styled.p`
    ${tw`relative font-serif font-light text-grey-light md:text-gr leading-tight`};
    &:before{
        content: "";
        display: inline-block;
        width: 4rem;
        height: 1px;
        background: #8E91A8;
        position: absolute;
        top: 15%;
        left: -5rem;
        opacity: 0.5;
    }
`

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            description: "",
            isSubmitting: false
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    handleInput(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    // handleSubmit = e => {
    //     // e.preventDefault();
    //     debugger
    //     fetch("/", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //         body: encode({ "form-name": "contact", ...this.state })
    //     })
    //         .then((res) => {debugger;console.log(res)})
    //         .catch(error => alert(error));

    //     e.preventDefault();
    // };

    handleSubmit(e) {
        let clickEvent = Object.assign({}, e);
        let self = this;
        debugger

        e.preventDefault();
        if (this.state.isSubmitting) {
            return;
        }
        // const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
        // const nameReg = /^[a-zA-Z ]{2,15}$/;
        // const phoneReg = /^[0-9]{10}$/;
        if (this.state.name.length < 1) {
            alert("Please Enter a Valid Name");
            return;
        }

        if (this.state.phone.length < 10) {
            alert("Please Enter a Valid Phone Number");
            return;
        }
        if (this.state.description.length <= 15) {
            alert("Please Tell us a bit more about your Project!");
            return;
        }
        this.setState({ isSubmitting: true });
        axios.get(`https://api.trumail.io/v2/lookups/json?email=${this.state.email}`)
            .then(res => {
                debugger
                // self.setState({ isSubmitting: false });
                if (res.status == 200) {
                    if (res.data.validFormat && res.data.deliverable) {

                        return self.submitForm(clickEvent);
                    }
                    else {
                        self.setState({ isSubmitting: false });
                        alert("Invalid Email");
                        return;
                    }
                }
            })
            .catch(err => { self.setState({ isSubmitting: false }); console.log(err); debugger })
    };

    submitForm = (e) => {
        let self = this;
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"), ...this.state
            })
        })
            .then(() => { self.setState({ isSubmitting: false }); alert("Success") })
            .catch(error => { self.setState({ isSubmitting: false }); alert(error) });
    }


    render() {
        return (
            <Layout withDarkMenu>
                <SectionOne>

                    <Row css={tw`items-center`}>
                        <div css={`${tw`w-full pl-8 md:w-auto`}`}>
                            <img css={`
                                    ${tw`mt-8`};
                                    height: 15rem;

                                `}
                                src={require('../images/coffee-artwork.svg')}
                                alt="Clearcut Coffee" />
                        </div>
                        <div css={tw`ml-8 mt-24 w-full md:w-auto`}>
                            <HeroTitle>Hey, Hi there!</HeroTitle>
                        </div>
                    </Row>

                    <Row justifyEnd css={tw`mt-6`}>
                        <div css={`${tw`w-full lg:w-2/3`};`}>

                            <Row>
                                <TitleDialogue>
                                    One little form from you to us,<br />
                                    could be one giant leap for mankind!<br />
                                    You never know.
                                </TitleDialogue>
                            </Row>

                            <Row>

                                <form name="contact"
                                    method="post"
                                    data-netlify="true"
                                    // action="/"
                                    data-netlify-honeypot="bot-field"
                                    css={`${tw`flex flex-wrap w-full mt-8`}`}
                                    onSubmit={this.handleSubmit}>

                                    <input type="hidden" name="form-name" value="contact" />
                                    <InputComponent
                                        onChange={(event) => this.handleInput(event)}
                                        label="Name"
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={this.state.name} />

                                    <div css={`${tw`w-full flex flex-wrap`}`}>
                                        <div css={`${tw`w-full lg:w-1/2`}`}>
                                            <InputComponent
                                                onChange={(event) => this.handleInput(event)}
                                                label="Email"
                                                type="email"
                                                name="email"
                                                placeholder="Your Email"
                                                value={this.state.email} />
                                        </div>
                                        <div css={`${tw`w-full lg:w-1/2`}`}>
                                            <InputComponent
                                                onChange={(event) => this.handleInput(event)}
                                                label="Phone"
                                                type="tel"
                                                name="phone"
                                                placeholder="999 999 9999"
                                                value={this.state.phone} />
                                        </div>
                                    </div>

                                    <InputComponent
                                        longText
                                        onChange={(event) => this.handleInput(event)}
                                        label="Can you tell us something about your project?"
                                        type="text"
                                        name="description"
                                        placeholder="A Good place to talk about your project"
                                        value={this.state.description} />

                                    <div css={tw`w-full my-12 flex md:justify-end`}>
                                        {/* <CustomLink css={tw`mt-8`}> */}
                                        <SubmitButton
                                            disabled={this.state.isSubmitting}
                                            // onClick={(e) => this.handleSubmit(e)}
                                            type="submit">
                                            {!this.state.isSubmitting ? "I'm done" : "Submitting ..."}
                                            {!this.state.isSubmitting && <LongArrow fill={"#"} css={`${tw`mt-2 ml-4`}`} />}
                                        </SubmitButton>
                                        {/* </CustomLink> */}
                                    </div>
                                </form>

                            </Row>

                        </div>
                    </Row>
                </SectionOne>
                <Footer />
            </Layout>

        );
    }

}

export default Contact;