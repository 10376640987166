import React, { useState } from 'react'
import { useSpring, animated, interpolate } from 'react-spring';

export default function LongArrow(args) {

    // const [active, setActive] = useState(true);

    const props = useSpring({ x: args.active ? 10 : 0 });


    return (

        <animated.svg
            {...args}
            width="50" height="16"
            viewBox="0 0 50 16" xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: props.x.interpolate(x => `translate3d(${x}%, 0 , 0)`)
            }}>
            <path d="M46.339 9H1a1 1 0 1 1 0-2h45.339L40.34 1.753A1 1 0 0 1 41.66.247l8 7a1 1 0 0 1 0 1.506l-8 7a1 1 0 0 1-1.318-1.506L46.34 9z"
                fill={args.fill ? args.fill : "#8E91A8"}
                fillRule="nonzero"
                opacity=".5" />

        </animated.svg>

    );
}