import React, { useState } from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { useSpring, animated, interpolate } from 'react-spring';

const Wrapper = styled(animated.div)`
    ${tw`flex flex-wrap items-center relative z-0 w-full my-4 md:px-2`};
    `
const StyledLabel = styled(animated.label)`
    ${tw`relative w-full font-secondary font-light text-grey-dark mb-2 ml-2`};
    letter-spacing: -0.36px;
    transform-origin: top left;
    `
const StyledInput = styled.input`
    ${tw`relative bg-grey-lightest overflow-hidden z-0 text-xl relative appearance-none outline-none shadow-none relative font-medium w-full py-4 pl-4 pr-2 border-none`}
    box-shadow: none;
    border: 2px solid ${props => props.active ? "hsla(234, 52%, 57%, 0.75)" : "transparent"};
    &::placeholder{
        ${tw`text-grey-light opacity-40 font-light font-sans`};
    }
`

const StyledTextArea = styled.textarea`
    ${tw`relative bg-grey-lightest overflow-hidden z-0 text-xl relative appearance-none outline-none shadow-none relative font-medium w-full py-4 pl-4 pr-2 border-none`}
    box-shadow: none;
    border: 2px solid ${props => props.active ? "hsla(234, 52%, 57%, 0.75)" : "transparent"};
    &::placeholder{
        ${tw`text-grey-light opacity-40 font-light font-sans`};
    }
`

export default function InputComponent(props) {

    const [active, setActive] = useState(false);

    const handleFocus = () => {
        alert(`focus called`);
        setActive(true);
    }

    const handleBlur = () => {
        alert(`blur called`);
        setActive(false);
    }

    return (
        <Wrapper>
            <StyledLabel>{props.label}</StyledLabel>
            {props.longText ?
                <StyledTextArea
                    active={active}
                    onFocus={() => setActive(true)}
                    onBlur={() => setActive(false)}
                    {...props} />
                : <StyledInput
                    active={active}
                    onFocus={() => setActive(true)}
                    onBlur={() => setActive(false)}
                    {...props} />
            }

        </Wrapper>
    );
}


InputComponent.defaultProps = {
    label: "Label",
    value: "",
}